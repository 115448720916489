import swal from "sweetalert";
import ReactDOM from "react-dom";
import React from "react";

export const showInstructions = () => {
    const container = document.createElement("div");

    ReactDOM.render(
        <div
            style={{
                width: "100%",
                textAlign: "right",
                direction: "rtl",
                lineHeight: "1.8em",
                fontSize: "16px",
            }}
            className="text-black"
        >
            <div
                style={{
                    gap: "20px",
                    padding: "10px",
                }}
                className="grid grid-cols-1 md:grid-cols-3"
            >
                {[
                    {
                        title: "💻 المنصة موقع ويب مش تطبيق",
                        details: [
                            "✅ المنصة هي موقع ويب، مش أبلكيشن، ومش محتاجة تحميل.",
                            "✔ تعمل على Google Chrome, Mozilla Firefox, Opera (آخر إصدار).",
                            "❌ المتصفحات القديمة قد تسبب مشاكل في التشغيل.",
                        ],
                    },
                    {
                        title: "📱 الأجهزة المتوافقة",
                        details: [
                            "✔ تعمل على الموبايل، اللاب توب، الكمبيوتر العادي.",
                            "❌ التابلت المدرسي غير مدعوم، حتى لو متهكر.",
                        ],
                    },
                    {
                        title: "🎓 تأكد من الكورس قبل الاشتراك",
                        details: [
                            "⚠️ بعد الاشتراك لا يمكن استرجاع أو تبديل الكورس.",
                            "✔ اقرأ تفاصيل الكورس (المحتوى، عدد الفيديوهات، مدة الاشتراك).",
                            "✔ لو مش متأكد، اسأل قبل الاشتراك.",
                        ],
                    },
                    {
                        title: "🔒 الحساب شخصي فقط",
                        details: [
                            "🚫 الحساب مخصص لاستخدامك الشخصي فقط.",
                            "❌ مشاركة الحساب يؤدي إلى إغلاقه نهائيًا.",
                        ],
                    },
                    {
                        title: "🌐 سرعة الإنترنت",
                        details: [
                            "✔ استخدم Wi-Fi قوي أو 4G مستقرة.",
                            "❌ الإنترنت الضعيف قد يسبب مشاكل في تشغيل الفيديوهات.",
                        ],
                    },
                ].map((section, index) => (
                    <div
                        key={index}
                        style={{
                            background: "#f8f9fa",
                            padding: "15px",
                        }}
                        className="flex-center-both flex-col space-y-4"
                    >
                        <h3
                            className="basis-full text-xl md:basis-1/2"
                            style={{
                                marginBottom: "8px",
                                fontWeight: "bold",
                                color: "#333",
                                borderRadius: "8px",
                            }}
                        >
                            {section.title}
                        </h3>
                        <ul
                            className="basis-full text-gray-600 text-base md:basis-1/2"
                            style={{ padding: "0 15px", margin: "0" }}
                        >
                            {section.details.map((item, i) => (
                                <li key={i} style={{ marginBottom: "5px" }}>
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>

            <p
                style={{
                    textAlign: "center",
                    marginTop: "20px",
                    fontWeight: "bold",
                    fontSize: "1.2em",
                }}
            >
                ✅ الرجاء الالتزام بهذه التعليمات لضمان أفضل تجربة تعليمية! 🚀
            </p>
        </div>,
        container
    );

    swal({
        title: "📖 تعليمات منصة مستر احمد عادل 2025 ⏳📅",
        content: container,
        icon: "info",
        button: "حسنًا",
        className: "w-[80%]",
    });
};
